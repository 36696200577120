import React, { Component } from 'react'
import { LoadCinuvee } from '../store/Metadata';
import axios from 'axios';
import YoutubeVideo from './YoutubeVideo';
import { Image } from 'react-bootstrap'
import MetaContext from './MetaContext';
import { collection, getDocs } from 'firebase/firestore';
import db from '../firebase';
import ReactPlayer from 'react-player';

export default class Cinuvee extends Component {
  constructor(props) {
    super(props)
    this.state = LoadCinuvee.init
    const search = window.location.pathname;
    const parts = search.split('/')
    const lastPart = parts[parts.length - 1]
    const urlId = lastPart.match(/\d+/)[0]
    this.state.code = urlId
  }

  player = null

  async componentDidMount() {
    const takeSnapshot = await getDocs(collection(db, 'meta_content_files'));
    takeSnapshot.forEach((doc) => {
      const findMovie = doc.data()
      this.setState({
        urlCode: findMovie.urlDescarga,
        metadata: findMovie,
        loadImg: findMovie.imgTitle,
      })
    })

    try {
      axios.defaults.headers.common['Authorization'] = 'e3c22a22d27dfea3ea6cce8d2a6fb2bf';
      const res = await axios.get('https://api.themoviedb.org/3/movie/' + this.state.code + '/videos?api_key=00f019191c205c1208fd3d615b9fb303&language=es-ES');
      const res2 = await axios.get('https://api.themoviedb.org/3/movie/' + this.state.code + '?api_key=00f019191c205c1208fd3d615b9fb303&language=es-ES')
      // const res3 = await axios.get('https://api.themoviedb.org/3/tv/'+this.state.code+'?api_key=00f019191c205c1208fd3d615b9fb303&language=es-ES')    
      if (res && res.data) {
        if (res.data.results[0]) {
          const keyUrl = this.state.dataUrl + res.data.results[0].key;
          this.setState({
            resurcesTx: res.data.results,
            key: res.data.results[0].key,
            keyUrl,
            titleArt: res2.data.title,
            loadResurses: true,
          });
        } else {
          const res3 = await axios.get('https://api.themoviedb.org/3/tv/' + this.state.code + '?api_key=00f019191c205c1208fd3d615b9fb303&language=es-ES')
          try {
            this.setState({
              key: res3.data.backdrop_path,
              titleArt: res3.data.title,
              loadResursesNotFound: true,
            });
          }
          catch (e) {
          }
        }
      }
    }
    catch (e) {
      this.state.loadResurses = false
    }
  }

  render() {

    return (
      <div className='just-start-flex w-100' style={{ background: `linear-gradient(rgba(255, 0, 0, 0.5), rgba(0, 0, 255, 0.5)), url(${this.state.loadImg})` }}>
        <MetaContext.Consumer>
          {metaStateA => <p></p>}
        </MetaContext.Consumer>
        {this.state.urlCode !== '' ? <><span>Ver Ahora</span><h2>{this.state.metadata.titleMovie}</h2><ReactPlayer url={this.state.urlCode} width={'100%'} controls={true} /></> : <></>}
        {this.state.loadResurses === true ?
          <h2 className='c-white txt-left' >Trailer de  {this.state.titleArt}</h2> : <></>}
        {this.state.loadResurses === true ?
          <YoutubeVideo keyVideo={this.state.key} /> : this.state.loadResursesNotFound === true ?
            <><h2 className='c-white txt-left' >{this.state.titleArt}</h2>
              <Image className='txt-left' width={'60%'} src={this.state.madeUrl + this.state.key} /></> : <><p>no loading</p></>}
      </div>
    )
  }
}
