import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardImg } from 'react-bootstrap';
import SpotifyPlayer from "react-spotify-web-playback"
import axios from 'axios'
 
class SpotifyAds extends Component {
    state = {
        topTracks: [],
        topSongs: [],
        selectedSong: null,
        token : 'BQBQhHYUbMhjSpzV7X9atPl9_Jpe7W5Ghf5-W6Xm8NqSln5LvCP7fT8tPtI9HzRRc2bxrocpqzCTbNxM0RH3_m84zUxJaEJ8VQyrmIq8sqMl0JdUnGZvx8Vn3LaHuIofYdN6Y5EHgS8PEll6vK1EOOO5F47wgArSeY4p0vskKk7RrDF133js2VBRbPnFldXWxwXwWV91g4izPLk9lHWBT8BDAzJV6VA2U4b4ySPhW2oat3Di21ULGsk4ByNENOU',

      };
    
      componentDidMount() {
        this.fetchTopTracks();
      }
    
      fetchTopTracks = async () => {
        try {
          const token = this.state.token; // Reemplaza esto con tu token de acceso de Spotify
          const response = await axios.get('https://api.spotify.com/v1/playlists/37i9dQZEVXbMDoHDwVN2tF/tracks', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
    
          const topTracks = response.data.items.map(item => {
            return {
              name: item.track.name,
              artist: item.track.artists[0].name,
              image: item.track.album.images[0].url
            };
          });
    
          this.setState({ topTracks });
        } catch (error) {     
        }
      };    
    
      render() {
          return (
          <div style={{ display: 'flex', flexWrap: 'wrap' , justifyContent: 'center'}}>
            {this.state.topTracks.map((track, index) => (
                <Card key={index} >
                    <CardBody>
                        <CardHeader title={track.name} />
                         <CardImg src={track.image} alt="song cover" style={{ width: '200px', height: '200px' }} />
                         <p>{track.artists}</p>
                    </CardBody>                     
                </Card>
              
            ))}
          </div>
        );
      }
    
}

export default SpotifyAds;


