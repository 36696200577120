import React, { Component } from 'react';

class ArtistFm extends Component {
  state = {
    artists: [],
  };

  componentDidMount() {
    const apiKey = 'e1563ec362e7084039dfce9a569c66eb';
    fetch(`http://ws.audioscrobbler.com/2.0/?method=chart.gettopartists&api_key=${apiKey}&format=json`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ artists: data.artists.artist });   
      })
      .catch((error) => {});
  }

  render() {
    const { artists } = this.state;

    return (
      <div className="lastfm-artists">
        {artists.map((artist, index) => (
          <div key={index} className="artist-card">
            <img src={artist.image[3]['#text']} alt={artist.name} />
            <h3>{artist.name}</h3>
          </div>
        ))}
      </div>
    );
  }
}

export default ArtistFm;