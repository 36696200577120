import React, { Component } from 'react'
import { Button, Card, CardBody, CardHeader, CardImg, CardText, Form, FormControl, InputGroup, Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap'
import { LoadAdminProfile } from '../../../store/Metadata'
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore'
import db, { storage } from '../../../firebase'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import BannerText from './Fonts/BannerText'
import GlitchText from './Fonts/GlitchText'
import NeonText from './Fonts/NeonText'
import RadiationText from './Fonts/RadiationText'
import ReactPlayer from 'react-player'



export default class AdminProfile extends Component {

  constructor(props) {
    super(props)
    this.state = LoadAdminProfile.init
  }

  async componentDidMount() {
    const getUserData = doc(db, 'usuarios_plus', localStorage.getItem('userState'))
    const loadUserData = await getDoc(getUserData)
    if (loadUserData.exists()) {
      this.setState({
        metaUserLoad: loadUserData.data(),
        profileImg: loadUserData.data().metaDataAccount.imgUser,
        passwordERSBoN: loadUserData.data().metaDataAccount.ERSBUserPass,
      })
    }
  }

  async getUrlTitle() {

    const getUserData = doc(db, 'usuarios_plus', localStorage.getItem('userState'))

    const loadResurses = () => {
      try {
        getDownloadURL(ref(storage, 'profile/Files/' + localStorage.getItem('userState') + '/portada.jpg'))
          .then((url) => {
            updateDoc(getUserData, { metaDataAccount: { imgUser: url } })        
            this.setState({
              profileImg: url,
            })
          }).catch((error) => {         
          })
      } catch (e) {
      }
    }
    loadResurses()
  }

  handleChangeImg() {
   alert('Contacta al : 8444950688')
  }

  pushThumbImg(c) {
    let fileData = c.target.files[0]
    this.setState({
      profileImg: c.target.files[0],
    })
    const storageRef = ref(storage, 'profile/Files/' + localStorage.getItem('userState') + '/portada.jpg');
    uploadBytes(storageRef, fileData).then((snapshot) => {
      this.getUrlTitle()
    })
  }


  inputPassword(e) {
    if (e.target.checked) {
      this.setState({
        modalPasswordERSB: true,
      })
    }
    else {
      this.setState({ modalPasswordERSB: true })
    }
  }

  async handleActiveERSB() {
    const getUserData = doc(db, 'usuarios_plus', localStorage.getItem('userState'))

  }


  render() {
    return (
      <>
        <div style={{display:'none'}}>
          <div>
            <div style={{ width: '250px' }}>
              <BannerText />
            </div>
            <GlitchText />
            <NeonText />
            <RadiationText />
          </div>
          <br />
          <br />
          <Form.Control
            style={{ display: 'none' }}
            id='chgImg'
            type='file'
            accept='image/png,image/jpeg'
            onChange={(e) => this.pushThumbImg(e)}
          />
        </div>
        <div className='z-25'>
          <div>
            <div style={{ objectFit: 'cover', textAlign: 'center' }} >
              <Card >
                <CardBody>
                  {/* <ReactPlayer height={'100%'} width={'100%'} loop={true}  url={'https://firebasestorage.googleapis.com/v0/b/nuvee-2f7f0.appspot.com/o/profile%2FPresentaci%C3%B3n1.mp4?alt=media&token=db684e5b-f1b2-4bc8-b1c2-a33ae1457a05'} controls={true} />
*/}
                  <img width={'40%'} onClick={() => { this.handleChangeImg() }} src={this.state.profileImg}></img>
                  <CardText>{localStorage.getItem('userState')}</CardText>
                </CardBody>
              </Card>
            </div>
          </div>

          <footer><br /></footer>


        </div>
        <div className='z-25-plus center-modal' style={{ zIndex: '30000!important', top: '40%', }} >
          <Modal className='z-25-plus center-modal' onHide={() => {
            this.setState({
              modalPasswordERSB: false
            })
          }} show={this.state.modalPasswordERSB}>

            <ModalBody  >
              <ModalTitle>Control ERBS</ModalTitle>
              <br />
              <InputGroup>
                <FormControl
                  type='password'
                  placeholder='Contraseña ERSB'
                  maxLength={6}
                  aria-label='ERSB-password'
                  onChange={(e) => {
                    this.setState({
                      passwordERSB: e.target.value,
                    })
                  }}
                />
              </InputGroup>
            </ModalBody>
            <ModalFooter><Button onClick={() => { this.handleActiveERSB() }} variant='success'>{this.state.ERSBactived === true ? 'Desactivar' : 'Activar'}</Button></ModalFooter>

          </Modal>
        </div>
      </>
    )
  }
}
